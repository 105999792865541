import React, { useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Parser } from "html-to-react";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineMinus } from "react-icons/ai";
// import { currencyFormatter } from "../utils/currencyFormatter";
import { BsMessenger, BsPlus } from "react-icons/bs";
import RelatedProduct from "../components/RelatedProduct";
import { addToSingleCart } from "../features/products/cartSlice";
import { useDispatch } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import ProductImageCarasol from "../components/ProductImageCarasol";
import { toast } from "react-toastify";
// import ProductCard from "../components/ProductCard";

// components start
const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // const { items: data } = useSelector((state) => state.products);
  // const { items: size } = useSelector((state) => state.sizes);

  const [singleProduct, setSingleProduct] = useState({});
  // console.log(singleProduct);

  console.log(singleProduct);
  // const [color, setColor] = useState([]);

  // const [size, setSize] = useState([]);
  // console.log(color);

  // const [colorAtr, setColorAtr] = useState("");
  const [imageThamb, setImageThamb] = useState([]);
  // const [sizeAtr, setSizeAtr] = useState("");
  const [size, setSize] = useState([]);

  const [response, setResponse] = useState({});
  console.log(response);

  const [selectedOption, setSelectedOption] = useState(null);

  const [cartQuantity, setCartQuantity] = useState(1);
  const htmlParser = new Parser();
  const navigate = useNavigate();
  useEffect(() => {
    const singleProduct = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-products/${id}`
      );
      return setSingleProduct(res.data);
    };
    singleProduct();
  }, [id]);

  useEffect(() => {
    const SizeAttribute = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-specificattribute/${id}`
      );
      return setSize(res.data);
    };
    SizeAttribute();
  }, [id]);

  useEffect(() => {
    if (selectedOption) {
      fetch(`${process.env.REACT_APP_URL}/api-findproductstock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productid: singleProduct?.product?.id,
          variantid: selectedOption,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data as needed
          console.log("Response from server:", data);
          setResponse(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedOption, singleProduct?.product?.id]);

  const handleRadioClick = (opstion) => {
    setSelectedOption(opstion);
    setCartQuantity(1);
  };

  if (response.discount_price > 0) {
  }
  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);
  const handleAddToCart = () => {
    if (!selectedOption) {
      toast.warn("Select your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      if (response.discount_price > 0) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.discount_price,
          })
        );
      }
      if (response.price > 0 && response.discount_price < 1) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.price,
          })
        );
      }

      // navigation("/checkout");
      setCartQuantity(1);
    }
  };
  const handleBuyNow = () => {
    if (!selectedOption) {
      toast.warn("seclect your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      if (response.discount_price > 0) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.discount_price,
          })
        );
      }
      if (response.price > 0 && response.discount_price < 1) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.price,
          })
        );
      }
      navigate("/checkout");
    }
  };

  useEffect(() => {
    const imageThambs = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-thumb/${singleProduct.product?.id}}`
      );

      return setImageThamb(res.data);
    };
    imageThambs();
  }, [singleProduct?.product?.id]);
  return (
    <div className="wrapper">
      {/* grid grid-cols-2 2xl:grid-cols-9 gap-10 */}
      <div className="grid lg:grid-cols-[50%_50%] 2xl:grid-cols-[38%_62%] gap-10">
        {/* col-span-2  md:col-span-9 lg:col-span-3 */}
        <div className="left image  p-3">
          {/* <div>
            <img
              src={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
              alt="pic"
            />
          </div> */}
          <ProductImageCarasol
            productImg={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
            imageThamb={imageThamb}
          />
        </div>
        {/* flex flex-col gap-8 col-span-2  md:col-span-9  lg:col-span-6 */}
        <div className="right flex flex-col gap-5 lg:gap-5 2xl:gap-8  p-3">
          <div className="font-semibold mt-5 text-lg lg:text-3xl">
            {singleProduct?.product?.name}
          </div>
          <div className="flex items-center justify-start gap-4 ">
            <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
              size
            </span>
            <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
              {size?.map((s, index) => (
                <button
                  key={index}
                  onClick={() => handleRadioClick(s.variant)}
                  className={`border border-red-500 py-3 px-4 ${
                    selectedOption === s.variant ? "bg-red-600 text-white" : ""
                  }`}
                >
                  {s.variant}
                </button>
              ))}
            </div>
          </div>

          <div className="font-medium flex items-center gap-4 text-3xl">
            <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
              price
            </span>
            {response.price > 0 ? (
              <>
                <div className="flex gap-5">
                  {response.discount_price > 0 ? (
                    <div className="flex gap-5">
                      <span className="font-normal line-through text-black decoration-200">
                        ৳ {+response?.price}
                      </span>
                      <span className="text-red-600 font-normal">
                        ৳ {+response.discount_price * cartQuantity}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <div className="text-red-600 font-normal">
                        ৳ {+response?.price * cartQuantity}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="text-red-600 font-light text-xl">
                <span>select your product size</span>
              </div>
            )}
          </div>

          <div className="flex items-center gap-3">
            <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
              Quantity
            </span>
            <div className="flex items-center border border-gray-300 gap-4 ">
              <button
                className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50 flex items-center justify-center"
                onClick={() => handleDecrease(singleProduct?.product)}
              >
                <AiOutlineMinus />
              </button>
              <span>{cartQuantity}</span>
              <button
                className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50 flex items-center justify-center"
                onClick={() => handleIncrease(singleProduct?.product)}
              >
                <BsPlus />
              </button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 text-white">
            <div className="flex gap-3 w-full col-span-2">
              <button
                onClick={handleBuyNow}
                disabled={!response.price}
                className={`${
                  !selectedOption || !response.price
                    ? "bg-red-600 cursor-not-allowed"
                    : "bg-red-600 hover:bg-[#3A3A3A]"
                }  py-3 px-5 w-full rounded-md col-span-2  text-white duration-300`}
              >
                {/* "w-full py-3 px-5 bg-[#C3161C] hover:bg-gray-700 flex items-center
              justify-center" */}
                <span className="text-base md:text-2xl">অর্ডার করুন</span>
              </button>
              <button
                onClick={handleAddToCart}
                disabled={!response.price}
                className={`${
                  !selectedOption || !response.price
                    ? "bg-red-600 cursor-not-allowed"
                    : "bg-red-600 hover:bg-[#3A3A3A]"
                }  py-3 px-5 w-full rounded-md col-span-2  text-white duration-300`}
              >
                {/* "w-full py-3 px-5 bg-[#C3161C] hover:bg-gray-700 flex items-center
              justify-center" */}
                <span className="text-base md:text-2xl">Add To Cart</span>
              </button>
            </div>
            {/* <button
              onClick={handleAddToCart}
              className="w-full bg-[#FA5303] py-3 px-5 flex items-center justify-center"
            >
              Add to Cart
            </button> */}

            <Link
              className="w-full py-2 px-5 bg-blue-500 hover:bg-[#ED423E] duration-300 col-span-2 flex flex-col items-center justify-center"
              to={`https://wa.me/+8801313888090`}
              target="_blank"
              rel="noreferrer"
            >
              <span>অর্ডার অথবা কল করতে ক্লিক করুন</span>
              <span>(+8801313888090)</span>
            </Link>

            <Link
              to={`#`}
              target="_blank"
              rel="noreferrer"
              className="w-full py-2 px-5 bg-blue-500 hover:bg-[#ED423E] duration-300 col-span-2 flex flex-col items-center justify-center"
            >
              <span>বিস্তারিত জানতে মেসেজ করুন</span>
              <span className="text-xl">
                <BsMessenger />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="details p-3">
        <SectionTitle title={"Discription"} />

        <div className="description w-[100%]  overflow-x-scroll">
          {singleProduct?.product?.description ? (
            <span className="">
              {htmlParser.parse(singleProduct?.product?.description)}
            </span>
          ) : (
            <div className="text-2xl text-red-500 flex items-center justify-center">
              <span>No Description</span>
            </div>
          )}
        </div>
      </div>
      <div className="mb-10">
        <RelatedProduct singleProduct={singleProduct} />
      </div>
    </div>
  );
};

export default ProductDetails;
